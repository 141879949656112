import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { z, ZodNullable, ZodNumber, ZodObject, ZodOptional, ZodRawShape, ZodString, ZodType } from 'zod';
import { useCallback, useMemo, useRef } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { getHookValue, HookValue } from '../util/hook-value';

interface FieldInfo {
  required: boolean;
  type: string | undefined;
}

export type ZodForm<T extends FieldValues> = UseFormReturn<T> & {
  getFieldInfo: (name: string) => FieldInfo;
  customReset(): void;
  getFormValue(): T;
};

function schemaDefault(value: ZodType): any {
  if (value instanceof ZodNullable) return null;
  if (value instanceof ZodOptional) value = value.unwrap();
  if (value instanceof ZodString) return '';
  if (value instanceof ZodNumber) return 0;
  if (value instanceof ZodObject) {
    const result: Record<string, any> = {};
    for (const key in value.shape) {
      result[key] = schemaDefault(value.shape[key]);
    }
    return result;
  }

  throw new Error(`Unsupported ZodType: ${typeof value}`);
}

interface ZodFormOptions<T extends ZodRawShape> {
  initialValues?: HookValue<Partial<z.infer<ZodObject<T>>>>;
  defaultValues?: HookValue<Partial<z.infer<ZodObject<T>>>>;
  /** Values not defined will be set to default, if value is undefined it will be preserved, any other value will be used for the reset */
  resetValues?: HookValue<Partial<z.infer<ZodObject<T>>>>;
}

export function useZodForm<T extends ZodRawShape>(
  config: T,
  options?: ZodFormOptions<T>
): ZodForm<z.infer<ZodObject<T>>> {

  const schema = useRef(z.object(config));

  // Create defaults values using `defaultValues` and schema
  const defaultValues = useMemo(() => {
    const base: Partial<z.infer<ZodObject<T>>> = getHookValue(options?.defaultValues) ?? {};

    const result: Partial<z.infer<ZodObject<T>>> = {};
    for (const key in schema.current.shape) {
      result[key] = base[key] || schemaDefault(schema.current.shape[key]);
    }

    return result as Required<z.infer<ZodObject<T>>>;
  }, []);

  const initialValues = useMemo(
    () => ({...defaultValues, ...getHookValue(options?.initialValues) ?? {}}),
    [defaultValues]
  );

  const form = useForm<z.infer<ZodObject<T>>>({
    resolver: zodResolver(schema.current),
    defaultValues: initialValues as any,
    mode: "onBlur"
  });

  const getFieldInfo = useCallback((name: string) => {
    const info: FieldInfo = {
      required: true,
      type: undefined
    };

    let lit = schema.current.shape[name];
    if (!lit) return info;

    if (lit instanceof ZodOptional) {
      lit = lit.unwrap();
      info.required = false;
    }

    if (lit instanceof ZodString) {
      switch (true) {
        case lit.isEmail:
          info.type = "email";
          break;
      }
    }

    return info;
  }, []);

  const customReset = useCallback(() => {
    if (!options?.resetValues) {
      form.reset();
      return;
    }

    const formValue = form.getValues();
    const reset = getHookValue(options.resetValues);

    const values: Record<string, any> = {};
    for (const key in reset) {
      if (reset[key] == undefined) values[key] = formValue[key];
      else values[key] = reset[key];
    }

    form.reset({...defaultValues, ...values});
  }, [form, options?.resetValues]);

  const getFormValue = useCallback(() => {
    const values = form.getValues();
    for (const key in values) {
      const optional = schema.current.shape[key] instanceof ZodOptional;
      if (!optional) continue;

      const value = values[key];
      if (!value) (values as any)[key] = undefined;
    }
    return values as z.infer<ZodObject<T>>;
  }, [form]);

  return {
    ...form,
    getFieldInfo,
    customReset,
    getFormValue
  }
}