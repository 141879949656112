import { z } from 'zod';

export interface AddressValue {
  street: string;
  postcode: string;
  city: string;
  streetNumber: string;
  letter?: string;
  door?: string;
  floor?: string;
}

export interface AddressOption extends AddressValue {
  label: string;
}

export const zAddressValue = z.object({
  street: z.string().min(1),
  postcode: z.string().min(1),
  city: z.string().min(1),
  streetNumber: z.string(),
  letter: z.string().optional(),
  door: z.string().optional(),
  floor: z.string().optional()
});

export type DkAddressResponse = {
  adresse: {
    vejnavn: string;
    postnr: string;
    postnrnavn: string;
    husnr: string;
    dør: string;
    etage: string;
  };
  tekst: string;
};

export type SeAddressResponse = {
  suggestions: {
    street: string;
    postcode: string;
    locality: string;
    street_number: string;
    letter: string;
    door: string;
    floor: string;
  }[]
};