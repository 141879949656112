import { useCallback, useMemo, useRef, useState } from 'react';

interface DebounceActions {
  clear(): void;
  force(): void;
}

interface DebounceOptions<T> {
  compare?: (a: T, b: T) => boolean;
}

interface Invocation {
  invoke: () => void;
}

export function useDebouncedCallback<T extends any[]>(
  callback: (...params: T) => void,
  delay: number,
  deps: unknown[],
  options?: DebounceOptions<T>,
): [(...args: T) => void, boolean, DebounceActions] {

  const lastVal = useRef<T|undefined>();
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const [invocation, setInvocation] = useState<Invocation|undefined>();

  const method = useCallback((...args: T) => {
    clearTimeout(timer.current);

    if (options?.compare && lastVal.current != null && options.compare(lastVal.current, args)) {
      setInvocation(undefined);
      return;
    }

    const invoke = () => {
      lastVal.current = args;
      setInvocation(undefined);
      callback(...args);
    };
    setInvocation({invoke});
    timer.current = setTimeout(invoke, delay);
  }, deps);

  const clear = useCallback(() => {
    clearTimeout(timer.current);
    setInvocation(undefined);
  }, []);

  const force = useCallback(() => {
    if (!invocation) return;
    clearTimeout(timer.current);
    invocation.invoke();
  }, [invocation, clear]);

  const waiting = useMemo(() => !!invocation, [invocation]);

  return [
    method, waiting, { clear, force }
  ];
}