import { ZodForm } from '../forms/zod-form';
import { FieldValues, Path, useController } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';
import { KeysOfTypeOrNull } from '@juulsgaard/ts-tools';

import { useTranslations } from '../../context/init-data.context';

interface Props<T extends FieldValues> {
  form: ZodForm<T>,
  name: KeysOfTypeOrNull<T, string> & Path<T>,
  type?: React.HTMLInputTypeAttribute;
  label: string,
  placeholder?: string,
  readonly?: boolean,
  autoComplete?: AutoFill|string
  helpText?: string
  multiline?: boolean;
}

export function TextInput<T extends FieldValues>({
  form,
  name,
  type,
  label,
  placeholder,
  autoComplete,
  readonly,
  helpText,
  multiline
}: Props<T>) {

  const fieldInfo = form.getFieldInfo(name);
  const { fieldState, field } = useController({ control: form.control, name });

  const { validationLabels: { optional } } = useTranslations();

  const labelEl = fieldInfo.required ? label : <>{label} <span style={{ marginLeft: "3px" }}>({optional})</span></>;

  return (
    <div className="app-input app-text-input">
      <TextField fullWidth type={type ?? fieldInfo.type ?? "text"}
                 label={labelEl} placeholder={placeholder ?? label} autoComplete={autoComplete}
                 error={fieldState.invalid} helperText={fieldState.error?.message ?? helpText}
                 margin="normal" multiline={multiline} minRows={multiline ? 4 : 1}
                 {...field}
                 value={field.value ?? ""} disabled={readonly}
      />
    </div>
  );

}