import { RefObject, useRef, useState } from 'react';

export function useSession<T extends Record<string, unknown>>(defaultValue: T): [session: T, newSession: (value?: T) => void, ref: RefObject<T>] {
  const ref = useRef<T>(defaultValue);
  const [session, setSession] = useState<T>(ref.current);
  return [
    session,
    val => {
      val ??= defaultValue;
      setSession(val);
      ref.current = val;
    },
    ref
  ];
}