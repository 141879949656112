import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { ZodForm } from '../../lib/forms/zod-form';
import { FieldValues, Path, useController } from 'react-hook-form';
import { isString, KeysOfType, KeysOfTypeOrNull } from '@juulsgaard/ts-tools';
import { HttpClient } from '../../lib/requests/http-client';
import { useRequestState } from '../../lib/requests/use-request-state';
import { useSession } from '../../lib/hooks/use-session';
import { useDebouncedCallback } from '../../lib/hooks/use-debounced-callback';
import {
  AddressOption, AddressValue, DkAddressResponse, SeAddressResponse, zAddressValue
} from './AddressInput.models';

import { useTranslations } from '../../context/init-data.context';
import { useHttpClient } from '../../lib/requests/HttpClientProvider';

//<editor-fold desc="Option Loading">
function loadDkOptions(client: HttpClient, query: string) {
  return client.get(`https://api.dataforsyningen.dk/adresser/autocomplete`)
    .withQuery('q', query)
    .go(parseDkResponses);
}

function parseDkResponses(responses: DkAddressResponse[]): AddressOption[] {
  return responses.map(x => (
    {
      label: x.tekst,
      street: x.adresse.vejnavn,
      streetNumber: x.adresse.husnr,
      letter: undefined,
      floor: x.adresse.etage || undefined,
      door: x.adresse.dør || undefined,
      postcode: x.adresse.postnr,
      city: x.adresse.postnrnavn
    }
  ) satisfies AddressOption);
}

function loadSeOptions(client: HttpClient, query: string) {
  return client.get(`https://app-api.geposit.se/v2.0/suggest/address/se`)
    .withQuery('api_key', process.env.REACT_APP_GEPOSIT_API_KEY)
    .withQuery('query', query)
    .go(parseSeResponses);
}

function parseSeResponses(response: SeAddressResponse): AddressOption[] {
  if (!response.suggestions?.length) return [];

  return response.suggestions.map(x => {
    const value = {
      street: x.street,
      streetNumber: x.street_number,
      letter: x.letter || undefined,
      floor: x.floor || undefined,
      door: x.door || undefined,
      postcode: x.postcode,
      city: x.locality
    } satisfies AddressValue as AddressOption;
    value.label = getValueOption(value);
    return value;
  });
}

//</editor-fold>

function getValueOption(value: AddressValue | AddressOption | string): string {
  if (isString(value)) return value;
  if ('label' in value) return value.label;
  const address = `${value.street ?? ''} ${value.streetNumber ?? ''} ${value.letter ?? ''} ${value.floor ?? ''} ${value.door ?? ''}`
    .trim().replace(/\s+/, ' ');
  return `${address}, ${value.postcode} ${value.city}`;
}

function getValueText(value: AddressValue | AddressOption | string | undefined | null): string {
  if (!value) return '';
  if (isString(value)) return value;
  return `${value.street ?? ''} ${value.streetNumber ?? ''} ${value.letter ?? ''} ${value.floor ?? ''} ${value.door ?? ''}`
    .trim().replace(/\s+/, ' ');
}

interface Props<T extends FieldValues> {
  form: ZodForm<T>;
  textName: KeysOfTypeOrNull<T, string> & Path<T>;
  dataName: KeysOfType<T, AddressValue | null> & Path<T>;
  label: string;
  onValueChange?: (value: AddressValue | undefined) => void;
  autoComplete?: AutoFill | string;
  loadingText?: string;
  noOptionsText?: string;
  countryCode: string;
}

export default function AddressInput<T extends FieldValues>({
  form,
  textName,
  dataName,
  label,
  onValueChange,
  autoComplete,
  loadingText,
  noOptionsText,
  countryCode
}: Props<T>) {

  const { validationLabels: { optional } } = useTranslations();
  const client = useHttpClient();

  const textControl = useController({ control: form.control, name: textName });
  const setText = useCallback((value: string | undefined) => {
    form.setValue(textName, value ?? '' as any, { shouldValidate: true });
  }, [form, textName]);
  const getText = useCallback(
    () => form.getValues(textName),
    [form, textName]
  );

  const dataControl = useController({ control: form.control, name: dataName });
  const setData = useCallback((value: AddressValue | undefined) => {
    onValueChange?.(value ?? undefined);
    form.setValue(dataName, value ?? null as any, { shouldValidate: true });
  }, [form, dataName, onValueChange]);
  const getData = useCallback(
    () => form.getValues(dataName),
    [form, dataName]
  );

  const [options, setOptions] = useState<AddressOption[]>([]);
  const [request, setRequest] = useRequestState<AddressOption[]>();

  // Get country specific load call
  const fetch = useMemo(() => {
    switch (countryCode.toLowerCase()) {
      case 'dk':
        return loadDkOptions;
      case 'se':
        return loadSeOptions;
      default:
        return loadDkOptions;
    }
  }, [countryCode]);

  // Debounced search function
  const [search, waiting, searchActions] = useDebouncedCallback((value: string) => {
    request.cancel();
    const req = fetch(client, value).then(response => setOptions(response));
    setRequest(req);
  }, 800, [fetch], { compare: ([a], [b]) => a === b });

  // Attempt searching for value
  const trySearch = useCallback((value: string) => {
    if (value.length < 3) {
      searchActions.clear();
      request.cancel();
      if (options.length) setOptions([]);
      return;
    }
    search(value);
  }, [search, options, searchActions, request]);

  const [session, newSession, sessionRef] = useSession({ set: false });

  // Try to select a value on blur
  const onBlur = useCallback(async () => {

    if (session.set) {
      searchActions.clear();
      request.cancel();
      return;
    }

    searchActions.force();
    const list = request.loading ? await request.promise?.catch() : options;
    if (!list || list.length !== 1) return;
    if (sessionRef.current !== session) return;

    setData(list[0]);
    setText(list[0].label);

  }, [session, searchActions, request, options, setData, setText]);

  // Reset state on focus
  const onFocus = useCallback(() => {
    newSession();
    searchActions.clear();
    trySearch(form.getValues(textName));
  }, [searchActions, trySearch, textName]);


  // When text is changed
  const onChange = useCallback((_event: SyntheticEvent, value: string, reason: string) => {
    if (reason === 'reset') return;
    if (value === getText()) return;

    if (getData()) setData(undefined);

    setText(value);
    trySearch(value);
  }, [setText, getText, getData, trySearch]);

  // When value is selected
  const onSelect = useCallback((_event: SyntheticEvent, value: AddressValue | string | null) => {
    if (isString(value)) return;
    session.set = true;
    setText(getValueText(value));
    setData(value ?? undefined);
  }, [session, setData, setText]);

  const loading = waiting || request.loading;

  const value = useMemo(
    () => zAddressValue.safeParse(dataControl.field.value).success
      ? dataControl.field.value as AddressValue
      : null,
    [dataControl.field.value]
  );

  const fieldInfo = form.getFieldInfo(textName);
  const labelEl = useMemo(
    () => fieldInfo.required ? label : <>{label} <span style={{ marginLeft: "3px" }}>({optional})</span></>,
    [fieldInfo.required, label]
  );

  return (
    <Autocomplete
      renderInput={params => (
        <TextField
          {...params} slotProps={{
          htmlInput: { ...params.inputProps, autoComplete: autoComplete },
          input: {
            ...params.InputProps,
            endAdornment: <>
              {loading && <CircularProgress color="inherit" size={20} />}
              {params.InputProps.endAdornment}
            </>
          }
        }}
          label={labelEl} margin="normal"
          error={textControl.fieldState.invalid} helperText={textControl.fieldState.error?.message} />
      )}
      options={options} getOptionLabel={getValueOption}
      filterOptions={o => o} loading={loading}
      clearOnBlur={false} selectOnFocus={false} openOnFocus blurOnSelect
      onChange={onSelect} value={value} freeSolo
      onBlur={onBlur} onFocus={onFocus}
      loadingText={loadingText} noOptionsText={noOptionsText}
      inputValue={textControl.field.value} onInputChange={onChange} />
  );
}